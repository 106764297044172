.session-tabs .MuiTabs-flexContainer {
  justify-content: center;
}

.session-tabs .MuiAppBar-root {
  margin: 0;
  padding: 0;
}
.session-tabs header .MuiTabs-root {
  margin: 10px 0;
  padding: 0;
}

.session-tabs .MuiPaper-elevation4 {
  box-shadow: none;
}

.session-tabs .MuiAppBar-colorPrimary {
  background-color: white;
  color: rgb(115, 115, 115);
}

.session-tabs .MuiTab-textColorInherit.Mui-selected {
  color: rgb(82, 113, 255);
  background-color: #e0e0e0;
}

.session-tabs .MuiTabs-indicator {
  background-color: rgb(82, 113, 255);
}
