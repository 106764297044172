.templates-left {
    margin: 0 0 0 20px;
}

.templates-center {
    margin: 80px 40px;
}

.templates-right {
    margin: 0 20px 0 0;
}