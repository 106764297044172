.row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.column {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}