.learnidy-font-size-xxlarge {
  font-size: 5rem !important;
}

.learnidy-cinnabar {
  color: #f69696;
}

.learnidy-orange {
  color: #f4a957 !important;
}

.learnidy-orange-bg {
  background-color: #f4a957 !important;
}

.learnidy-orange-border {
  border-color: #f4a957 !important;
}

.learnidy-purple {
  color: #bb6bd9;
}

.learnidy-blue {
  color: #6e86f4;
}

.learnidy-blue-bg {
  background-color: #6e86f4 !important;
}
