.logo {
  height: 40px;
  width: 40px;
  text-align: center;
  margin: 0 10px 0 0px;
  padding: 0;
  background-color: white;
  border-radius: 0.3em;
}

.company-name {
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: white;
  margin: 0;
  padding: 0;
}

.user-bubble {
  min-width: 45px;
  padding-right: 10px;

  margin-left: auto;
}

.header-signout .hydrated .button {
  background-color: #6e86f4;
}

.header-signout .hydrated .button:hover {
  background-color: rgb(116, 141, 255);
}
