.class-activity {
  /* width: 20em; */
  padding: 0 10px 10px 0;
}

.first-half {
  color: white;
  min-width: 54px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.second-half {
  width: 80%;
}

.activity-first-half-duration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activity-first-half-duration span:first-child {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3;
}

.activity-first-half-duration span:last-child {
  font-size: 0.7rem;
  line-height: 1;
}

.class-activity CardContent {
  margin: 0px;
  padding: 0px;
}

.class-activity .activity {
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.class-activity .activity-content {
  margin: 0 0 0 10px;
}

.class-activity .activity-name {
  text-align: left;
  margin: 0 0 10px 0;
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(115, 115, 115);
}

.class-activity .activity-desc {
  text-align: left;
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(115, 115, 115);
}

.class-activity .activity-icon-btns {
  text-align: right;
  margin: 0px;
  padding: 0px;
  line-height: 0;
}

.class-activity .edit-activity-btn,
.class-activity .delete-activity-btn {
  font-size: large;
  margin: 0 4px 0 0;
  padding: 0;
}

.session-builder p {
  margin-left: 15px;
}

.new-activity-form {
  margin-top: 20px;
  margin-left: 55px;
}

.add-activity-btn {
  margin-left: 15px;
}

.session-timer {
  margin-left: 55px;
}
