@import url(https://fonts.googleapis.com/css?family=Nunito);
.logo {
  height: 40px;
  width: 40px;
  text-align: center;
  margin: 0 10px 0 0px;
  padding: 0;
  background-color: white;
  border-radius: 0.3em;
}

.company-name {
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: white;
  margin: 0;
  padding: 0;
}

.user-bubble {
  min-width: 45px;
  padding-right: 10px;

  margin-left: auto;
}

.header-signout .hydrated .button {
  background-color: #6e86f4;
}

.header-signout .hydrated .button:hover {
  background-color: rgb(116, 141, 255);
}

.learnidy-font-size-xxlarge {
  font-size: 5rem !important;
}

.learnidy-cinnabar {
  color: #f69696;
}

.learnidy-orange {
  color: #f4a957 !important;
}

.learnidy-orange-bg {
  background-color: #f4a957 !important;
}

.learnidy-orange-border {
  border-color: #f4a957 !important;
}

.learnidy-purple {
  color: #bb6bd9;
}

.learnidy-blue {
  color: #6e86f4;
}

.learnidy-blue-bg {
  background-color: #6e86f4 !important;
}

.class-activity {
  /* width: 20em; */
  padding: 0 10px 10px 0;
}

.first-half {
  color: white;
  min-width: 54px;
  padding-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.second-half {
  width: 80%;
}

.activity-first-half-duration {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.activity-first-half-duration span:first-child {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.3;
}

.activity-first-half-duration span:last-child {
  font-size: 0.7rem;
  line-height: 1;
}

.class-activity CardContent {
  margin: 0px;
  padding: 0px;
}

.class-activity .activity {
  border: none;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
}

.class-activity .activity-content {
  margin: 0 0 0 10px;
}

.class-activity .activity-name {
  text-align: left;
  margin: 0 0 10px 0;
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(115, 115, 115);
}

.class-activity .activity-desc {
  text-align: left;
  font-family: Nunito, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(115, 115, 115);
}

.class-activity .activity-icon-btns {
  text-align: right;
  margin: 0px;
  padding: 0px;
  line-height: 0;
}

.class-activity .edit-activity-btn,
.class-activity .delete-activity-btn {
  font-size: large;
  margin: 0 4px 0 0;
  padding: 0;
}

.session-builder p {
  margin-left: 15px;
}

.new-activity-form {
  margin-top: 20px;
  margin-left: 55px;
}

.add-activity-btn {
  margin-left: 15px;
}

.session-timer {
  margin-left: 55px;
}



.increment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.increment .MuiFab-root {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.increment .increment-text {
  margin: 0 4px;
}
.row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}
.home-left {
    margin: 0 0 0 20px;
}

.home-center {
    margin: 80px 40px;
}

.home-right {
    margin: 0 20px 0 0;
}
.session-tabs .MuiTabs-flexContainer {
  -webkit-justify-content: center;
          justify-content: center;
}

.session-tabs .MuiAppBar-root {
  margin: 0;
  padding: 0;
}
.session-tabs header .MuiTabs-root {
  margin: 10px 0;
  padding: 0;
}

.session-tabs .MuiPaper-elevation4 {
  box-shadow: none;
}

.session-tabs .MuiAppBar-colorPrimary {
  background-color: white;
  color: rgb(115, 115, 115);
}

.session-tabs .MuiTab-textColorInherit.Mui-selected {
  color: rgb(82, 113, 255);
  background-color: #e0e0e0;
}

.session-tabs .MuiTabs-indicator {
  background-color: rgb(82, 113, 255);
}

.class-root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.class-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: clamp(350px, 800px, 80%);
}

.class-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.class-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.class-info .class-title {
  text-align: center;
  margin-bottom: 40px;
}

.class-input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 0 0 22px 0;
}

.class-form .class-label {
  margin: 0 0 8px 0;
  font-weight: bold;
}

.class-multi-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.class-row-flex-start {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
}

.class-row-flex-start .MuiFormControl-root {
  margin-right: 30px;
}

.class-start-date {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 0 20px 0 0;
}

.class-times {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: baseline;
          align-items: baseline;
  width: 45%;
}

.class-times label {
  margin: 0 10px;
}

.class-start-date .MuiFormControl-root {
  width: 100%;
}

.class-upload-label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.class-form .class-upload-button {
  background-color: #c4c4c4;
}

.class-objective-table {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px;
}

.class-objective-table label {
  font-weight: bold;
  text-decoration: underline;
}

.class-objective-table-col {
  text-align: center;
}

.class-col-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.class-col-space-between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.class-col-center {
  -webkit-justify-content: center;
          justify-content: center;
}

.class-row-center {
  -webkit-align-items: center;
          align-items: center;
}

.class-add-objectives {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.class-add-objectives button {
  margin: 10px 0;
  color: white;
}

.class-footer {
  text-align: center;
  margin-bottom: 50px;
}

.class-footer .class-next-button {
  width: 10em;
  color: white;
}

.class-multi-item .class-select .class-blooms {
  -webkit-transform: translate(14px, -15px) scale(0.75);
          transform: translate(14px, -15px) scale(0.75);
}

.templates-left {
    margin: 0 0 0 20px;
}

.templates-center {
    margin: 80px 40px;
}

.templates-right {
    margin: 0 20px 0 0;
}


