.class-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.class-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(350px, 800px, 80%);
}

.class-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.class-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.class-info .class-title {
  text-align: center;
  margin-bottom: 40px;
}

.class-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 22px 0;
}

.class-form .class-label {
  margin: 0 0 8px 0;
  font-weight: bold;
}

.class-multi-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.class-row-flex-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
}

.class-row-flex-start .MuiFormControl-root {
  margin-right: 30px;
}

.class-start-date {
  flex-grow: 1;
  margin: 0 20px 0 0;
}

.class-times {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  width: 45%;
}

.class-times label {
  margin: 0 10px;
}

.class-start-date .MuiFormControl-root {
  width: 100%;
}

.class-upload-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.class-form .class-upload-button {
  background-color: #c4c4c4;
}

.class-objective-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.class-objective-table label {
  font-weight: bold;
  text-decoration: underline;
}

.class-objective-table-col {
  text-align: center;
}

.class-col-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.class-col-space-between {
  justify-content: space-between;
}

.class-col-center {
  justify-content: center;
}

.class-row-center {
  align-items: center;
}

.class-add-objectives {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.class-add-objectives button {
  margin: 10px 0;
  color: white;
}

.class-footer {
  text-align: center;
  margin-bottom: 50px;
}

.class-footer .class-next-button {
  width: 10em;
  color: white;
}

.class-multi-item .class-select .class-blooms {
  transform: translate(14px, -15px) scale(0.75);
}
