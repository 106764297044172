

.increment {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.increment .MuiFab-root {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.increment .increment-text {
  margin: 0 4px;
}